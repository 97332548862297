@import "variables";

::-webkit-scrollbar-button {
  display: none;
  height: 13px;
  border-radius: 0px;
  background-color: $light;
}

::-webkit-scrollbar-button:hover {
  background-color: $light;
}

::-webkit-scrollbar-thumb {
  background-color: $mainColor;
}

::-webkit-scrollbar-track {
  background-color: $light;
}

::-webkit-scrollbar-track:hover {
  background-color: $light;
}

::-webkit-scrollbar {
  width: 3px;
}

::selection {
  background: $light;
  /* WebKit/Blink Browsers */
}

::-moz-selection {
  background: $light;
  /* Gecko Browsers */
}

@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro');

body {
  font-family: 'Source Sans Pro', sans-serif;
  background-color: $light;
  color: $dark;
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  padding: 0px;
  margin: 0px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a{
  color: $mainColor;
}

.App{
  text-align: center;
  width: 100%;
}

.App-link {
  color: $mainColor;
}

.logo{
  max-width: 60px;
}

@media (max-width: 600px) {
  .logo{
    max-width: 50px;
  }
}

.nav-wrapper{
  overflow: hidden;
  color: $light;
  background-color: $dark;
  position: fixed;
  z-index: 10;
}

.page{
  padding-top: 40px;
  padding-bottom: 50px;
}

.profilPage{
  padding-top: 80px;
  height: 100vh;
  overflow-y: scroll!important;
}

.groupPage{
  padding-top: 60px;
}

.galleriePage{
  padding-top: 100px;
}

.logPage{
  padding-top: 80px;
  padding-bottom: 50px;
}

.groups-dashboard{
 max-height: 70vh;
 overflow-x: hidden;
 overflow-y: scroll;
}

.welcomeMsg{
  font-size: 1.3em;
  font-weight: 500;
  padding-left: 15px;
}

@media (max-width: 600px) {
  .welcomeMsg{
    display: none;
  }
}

.welcomeMsgInfo{
  font-size: 1em;
  font-weight: 500;
}

.mlText{
  text-align: justify;
}

.textTitle{
  font-weight: 600;
  font-size: 1.5em;
}

.menuIcon{
  color: $light;
  padding-top: 5px;
}

@media (max-width: 600px) {
  .menuIcon{
    padding-top: 0px;
  }
}

.logOutBtn{
  cursor: pointer;
  padding-left: 15px;
  color: $bad;
}

.btn{
  background-color: $dark;
  color: $light;
  border-radius: $mainBorder;
}

.btn:hover{
  background-color: $mainColor;
  color: $dark;
}

.dropzoneProfile{
  height: 100px;
  border-style: dashed;
  border-radius: $mainBorder;
  background-color: $lightPlus;
  text-align: center;
  width: 200px;
  padding: 10px;
  margin: 0 auto;
  margin-bottom: 15px;
}

.profilBtn{
  margin-top: 10px;
  border-radius: $mainBorder;
}

.profileDatePicker{
  margin-top: -20px!important;
}

.profilePseudoInput{
  margin-top: -5px;
}

#avatarScale{
  outline: none!important;
  border: none!important;
  width: 200px;
}

.ril-toolbar{
  position: fixed!important;
  bottom: 0px!important;
  padding-left: 10px;
  padding-right: 10px;
}
.ril__toolbar{
  top: auto;
}
.ril-zoom-in{
  display: none!important;
}
.ril-zoom-out{
  display: none!important;
}
.ril-close{
  width: 40px!important;
  height: 40px!important;
  margin-right: -25px;
  border-radius: 50px;
}

.ril__toolbarItem{
  overflow: visible;
}
.ril__navButtons{
  display: none!important;
}
