$mainColor: #59b355;
$secondaryColor: #a39c37;

$dark: #141414;
$grey: #797979;
$light: #cacaca;
$lightPlus: #efefef;

$selectionColor: #77a0cf;
$replyColor:#e0cf6f;

$good: #4da05b;
$bad: #a04d4d;

$white: #f3f3f3;

$mainBorder: 5px;

$msgColor:#9ac2a1;
$msgResponse:#ddebdf;