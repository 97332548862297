@import "../../variables";
::-webkit-scrollbar { 
  display: none!important;
}
.galleriePage{
  .gridItem{
    background-color: red!important;
    width: 100%!important;
    height: 100%!important;
  }
  padding-bottom: 20px;

  .titleImg{
    width: 100px;
    height: 25px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin: 0 auto;
  }
  .card{
    margin-bottom: 0px;
    margin-top: 0px;
    border-radius: $mainBorder;
    padding-right: 0px;
    margin-right: 0px;
    margin: 0 auto;
    width: 120px;
    margin-bottom: 10px;
  }
  .card-action{
    color: $grey;
    font-size: 8pt;
    padding: 3px;
  }

  .gallerieImgContainer{
    overflow: hidden;
    width: 120px;
    height: 120px;
    margin: 0 auto;
    -webkit-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.30);
    -moz-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.30);
    box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.30);
    margin-bottom: 5px;

    .gallerieImg{
      cursor: pointer;
      width: 120px!important;
      display: block;
      min-width: 100px;
      margin: 0 auto;
    }
  }

    #grid {
      
        margin-top: -25px;
        display: grid;
        grid-template-columns: 1fr;
      }
      
      @media (min-width: 300px) {
        #grid {
          grid-template-columns: repeat(2, 1fr);
          grid-gap: 10px
        }
      }
      
      @media (min-width: 480px) {
        #grid {
          grid-template-columns: repeat(2, 1fr);
        }
      }
      
      @media (min-width: 645px) {
        #grid {
          grid-template-columns: repeat(3, 1fr);
        }
      }
      
      @media (min-width: 900px) {
        #grid {
          grid-template-columns: repeat(4, 1fr);
        }
      }
      
      @media (min-width: 1350px) {
        #grid {
          grid-template-columns: repeat(5, 1fr);
        }
      }
      
      @media (min-width: 1800px) {
        #grid {
          grid-template-columns: repeat(6, 1fr);
        }
      }
      
      @media (min-width: 3000px) {
        #grid {
          grid-template-columns: repeat(7, 1fr);
        }
      }
      
      @media (min-width: 3500px) {
        #grid {
          grid-template-columns: repeat(8, 1fr);
        }
      }
      
      @media (min-width: 4000px) {
        #grid {
          grid-template-columns: repeat(9, 1fr);
        }
      }
}