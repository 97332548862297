@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro);
.msgMessage {
  text-align: left;
  font-size: 1.1em;
  padding-right: 20px;
  padding-left: 20px;
  word-wrap: break-word;
  margin-top: 25px;
  margin-bottom: 10px; }

.msgAuthor {
  position: absolute;
  font-size: 0.9em;
  display: inline;
  font-weight: 600;
  padding-left: 20px;
  top: 5px;
  left: -10px; }

.msgDate {
  position: absolute;
  font-size: 0.8em;
  display: inline;
  padding-right: 20px;
  bottom: 5px;
  right: -10px;
  color: #797979; }

.msgAvatarContainer {
  width: 25px;
  height: 25px;
  overflow: hidden;
  position: absolute;
  right: -5px;
  top: -5px;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.75);
  background-color: #cacaca;
  border-radius: 5px; }
  .msgAvatarContainer .msgAvatar {
    max-width: 25px;
    max-height: 25px; }

.msgBlock {
  position: relative;
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 5px;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  min-height: 50px !important;
  cursor: pointer; }

.msgOthers {
  background-color: #efefef; }
  .msgOthers .msgBulle {
    color: #efefef; }

.msgUser {
  background-color: #9ac2a1; }
  .msgUser .msgBulle {
    color: #9ac2a1; }

.msgBulle {
  position: absolute;
  left: -10px;
  top: -3px;
  font-size: 30pt;
  -webkit-transform: rotate(0.25turn);
          transform: rotate(0.25turn);
  z-index: -1; }

.msgDateInfo {
  display: block;
  background-color: #efefef;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 20px;
  margin-left: 10px !important; }

.msgOverlay {
  background-color: transparent;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  display: block;
  z-index: 0 !important;
  position: absolute; }

.msgSelected {
  background-color: #77a0cf !important; }

.msgResponse {
  background-color: #ddebdf;
  margin-top: 25px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 5px;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.3);
  height: 80px; }

.responseAuthor {
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: left;
  font-style: italic; }

.responseMessageContainer {
  width: 100%; }
  .responseMessageContainer .responseMessage {
    font-style: italic;
    padding-bottom: 5px;
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding-right: 60px; }
  .responseMessageContainer .quoteImgContainer {
    position: relative;
    display: block;
    width: 50px;
    height: 50px;
    float: right;
    top: -50px;
    overflow: hidden;
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.75);
    background-color: #cacaca;
    border-radius: 5px;
    margin: 0px; }
    .responseMessageContainer .quoteImgContainer .quoteImg {
      margin: 0 auto;
      max-width: 50px;
      max-height: 50px; }

.msgLinkContainer {
  overflow: hidden;
  position: relative;
  z-index: 2 !important;
  height: 150px;
  width: 150px;
  top: 10px;
  margin-left: 0px;
  margin-top: 25px;
  margin-bottom: -10px;
  padding-bottom: -10px;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.75);
  border-radius: 5px; }
  .msgLinkContainer .msgLink {
    width: 150px;
    height: 150px;
    display: block; }

.replyIcon {
  -webkit-transform: rotate(0.5turn);
          transform: rotate(0.5turn);
  padding-bottom: 7px; }

.chatPage {
  overflow-y: hidden !important;
  margin: 0px; }
  .chatPage .witnessResponse {
    z-index: 25;
    position: fixed;
    right: 0px;
    left: 0px;
    height: auto;
    bottom: 55px;
    margin-left: 23px;
    margin-right: 60px;
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.75);
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    background-color: #e0cf6f;
    border-radius: 5px; }
    .chatPage .witnessResponse .cancelResponse {
      position: absolute;
      right: 3px;
      top: 3px;
      cursor: pointer; }
  .chatPage .conversation {
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    margin-top: 15px;
    padding-bottom: 50px;
    margin-bottom: -20px; }
  .chatPage .sender {
    position: fixed;
    bottom: 0px;
    left: 0px;
    z-index: 12 !important;
    width: 100%;
    height: 60px; }
    .chatPage .sender .senderBlock {
      box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.75);
      width: 100%;
      background-color: #cacaca !important;
      z-index: 12 !important;
      bottom: 0px;
      position: absolute;
      display: block;
      left: 0px;
      height: 60px;
      margin-left: 0px; }
      .chatPage .sender .senderBlock .cancelLink {
        position: absolute;
        z-index: 20;
        bottom: 210px;
        left: 145px;
        background-color: #cacaca;
        padding: 2px;
        border-radius: 25px;
        cursor: pointer; }
      .chatPage .sender .senderBlock .chatLinkContainer {
        width: 150px;
        height: 150px;
        position: absolute;
        bottom: 75px;
        left: 12px;
        overflow: hidden;
        box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.75) !important;
        border-radius: 5px !important; }
        .chatPage .sender .senderBlock .chatLinkContainer .chatLinkPreview {
          width: 100px;
          background-color: #cacaca;
          height: 100px;
          overflow: hidden;
          border-radius: 5px;
          box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.75); }
          .chatPage .sender .senderBlock .chatLinkContainer .chatLinkPreview .chatLinkPreviewImg {
            min-width: 100px;
            max-width: 100px;
            padding: 5px; }
      .chatPage .sender .senderBlock .inputChat {
        height: 100%;
        width: 100%;
        max-width: 100%;
        padding-right: 130px !important; }
        .chatPage .sender .senderBlock .inputChat .counterMsg {
          position: absolute;
          left: 20px;
          top: 40px;
          font-size: 0.8em; }
        .chatPage .sender .senderBlock .inputChat .inputContactChat {
          border-bottom: 1px solid #141414 !important;
          position: relative;
          left: 15px;
          bottom: 5px; }
      .chatPage .sender .senderBlock .senderBlockButtons {
        position: fixed;
        bottom: 5px;
        right: 10px; }
        .chatPage .sender .senderBlock .senderBlockButtons .btnChatImg {
          position: absolute;
          margin-top: 2px;
          right: 50px; }
        .chatPage .sender .senderBlock .senderBlockButtons .btnSender {
          background-color: transparent !important;
          color: #141414 !important;
          border: none; }
        .chatPage .sender .senderBlock .senderBlockButtons .btnSenderDisabled {
          background-color: transparent !important;
          color: #797979 !important;
          border: none; }
        .chatPage .sender .senderBlock .senderBlockButtons .senderIcon {
          font-size: 25pt !important;
          cursor: pointer; }

.loading {
  position: fixed;
  top: 0px;
  left: 0px;
  padding: 0px;
  margin: 0px;
  width: 100vw;
  height: 100vh;
  display: block;
  z-index: 50;
  background-color: rgba(0, 0, 0, 0.9);
  color: #f3f3f3; }

.loadingGif {
  margin: 0 auto;
  width: 200px;
  margin-top: 50vh;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.btnToBottom {
  cursor: pointer;
  position: fixed;
  z-index: 15;
  border-radius: 25px;
  background-color: #efefef !important;
  width: 30px;
  height: 30px;
  border: none;
  bottom: 77px;
  right: 15px;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.75); }
  .btnToBottom .btnToBottomIcon {
    font-size: 14pt;
    padding-top: 2px; }

.btnToBottom:hover {
  background-color: #efefef !important; }

.btnToBottom:link {
  background-color: #efefef !important; }

.btnToBottom:active {
  background-color: #efefef !important; }

::-webkit-scrollbar {
  display: none !important; }

.galleriePage {
  padding-bottom: 20px; }
  .galleriePage .gridItem {
    background-color: red !important;
    width: 100% !important;
    height: 100% !important; }
  .galleriePage .titleImg {
    width: 100px;
    height: 25px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin: 0 auto; }
  .galleriePage .card {
    margin-bottom: 0px;
    margin-top: 0px;
    border-radius: 5px;
    padding-right: 0px;
    margin-right: 0px;
    margin: 0 auto;
    width: 120px;
    margin-bottom: 10px; }
  .galleriePage .card-action {
    color: #797979;
    font-size: 8pt;
    padding: 3px; }
  .galleriePage .gallerieImgContainer {
    overflow: hidden;
    width: 120px;
    height: 120px;
    margin: 0 auto;
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.3);
    margin-bottom: 5px; }
    .galleriePage .gallerieImgContainer .gallerieImg {
      cursor: pointer;
      width: 120px !important;
      display: block;
      min-width: 100px;
      margin: 0 auto; }
  .galleriePage #grid {
    margin-top: -25px;
    display: grid;
    grid-template-columns: 1fr; }
  @media (min-width: 300px) {
    .galleriePage #grid {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 10px; } }
  @media (min-width: 480px) {
    .galleriePage #grid {
      grid-template-columns: repeat(2, 1fr); } }
  @media (min-width: 645px) {
    .galleriePage #grid {
      grid-template-columns: repeat(3, 1fr); } }
  @media (min-width: 900px) {
    .galleriePage #grid {
      grid-template-columns: repeat(4, 1fr); } }
  @media (min-width: 1350px) {
    .galleriePage #grid {
      grid-template-columns: repeat(5, 1fr); } }
  @media (min-width: 1800px) {
    .galleriePage #grid {
      grid-template-columns: repeat(6, 1fr); } }
  @media (min-width: 3000px) {
    .galleriePage #grid {
      grid-template-columns: repeat(7, 1fr); } }
  @media (min-width: 3500px) {
    .galleriePage #grid {
      grid-template-columns: repeat(8, 1fr); } }
  @media (min-width: 4000px) {
    .galleriePage #grid {
      grid-template-columns: repeat(9, 1fr); } }

.avatarUserListContainer {
  border-radius: 5px;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.75);
  width: 100px;
  height: 100px;
  overflow: hidden;
  margin: 0 auto; }
  .avatarUserListContainer .avatarUserList {
    min-width: 100px;
    max-width: 100px; }

.avatarProfile {
  border-radius: 5px;
  width: 120px;
  margin-bottom: 15px; }

.userItem {
  overflow: hidden;
  position: relative;
  cursor: pointer;
  background-color: #f3f3f3;
  border-radius: 5px;
  margin: 5px;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.3); }

.groupPage {
  height: 100vh;
  overflow-y: scroll !important; }
  .groupPage #grid {
    display: grid;
    grid-template-columns: 1fr; }
  @media (min-width: 400px) {
    .groupPage #grid {
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 5px; } }
  @media (min-width: 480px) {
    .groupPage #grid {
      grid-template-columns: repeat(2, 1fr); } }
  @media (min-width: 645px) {
    .groupPage #grid {
      grid-template-columns: repeat(3, 1fr); } }
  @media (min-width: 900px) {
    .groupPage #grid {
      grid-template-columns: repeat(4, 1fr); } }
  @media (min-width: 1350px) {
    .groupPage #grid {
      grid-template-columns: repeat(5, 1fr); }
    .groupPage #filterFolio {
      width: calc(100% - 300px); } }
  @media (min-width: 1800px) {
    .groupPage #grid {
      grid-template-columns: repeat(6, 1fr); } }
  @media (min-width: 3000px) {
    .groupPage #grid {
      grid-template-columns: repeat(7, 1fr); } }
  @media (min-width: 3500px) {
    .groupPage #grid {
      grid-template-columns: repeat(8, 1fr); } }
  @media (min-width: 4000px) {
    .groupPage #grid {
      grid-template-columns: repeat(9, 1fr); } }

::-webkit-scrollbar-button {
  display: none;
  height: 13px;
  border-radius: 0px;
  background-color: #cacaca; }

::-webkit-scrollbar-button:hover {
  background-color: #cacaca; }

::-webkit-scrollbar-thumb {
  background-color: #59b355; }

::-webkit-scrollbar-track {
  background-color: #cacaca; }

::-webkit-scrollbar-track:hover {
  background-color: #cacaca; }

::-webkit-scrollbar {
  width: 3px; }

::-moz-selection {
  background: #cacaca;
  /* WebKit/Blink Browsers */ }

::selection {
  background: #cacaca;
  /* WebKit/Blink Browsers */ }

::-moz-selection {
  background: #cacaca;
  /* Gecko Browsers */ }

body {
  font-family: 'Source Sans Pro', sans-serif;
  background-color: #cacaca;
  color: #141414;
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  padding: 0px;
  margin: 0px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

a {
  color: #59b355; }

.App {
  text-align: center;
  width: 100%; }

.App-link {
  color: #59b355; }

.logo {
  max-width: 60px; }

@media (max-width: 600px) {
  .logo {
    max-width: 50px; } }

.nav-wrapper {
  overflow: hidden;
  color: #cacaca;
  background-color: #141414;
  position: fixed;
  z-index: 10; }

.page {
  padding-top: 40px;
  padding-bottom: 50px; }

.profilPage {
  padding-top: 80px;
  height: 100vh;
  overflow-y: scroll !important; }

.groupPage {
  padding-top: 60px; }

.galleriePage {
  padding-top: 100px; }

.logPage {
  padding-top: 80px;
  padding-bottom: 50px; }

.groups-dashboard {
  max-height: 70vh;
  overflow-x: hidden;
  overflow-y: scroll; }

.welcomeMsg {
  font-size: 1.3em;
  font-weight: 500;
  padding-left: 15px; }

@media (max-width: 600px) {
  .welcomeMsg {
    display: none; } }

.welcomeMsgInfo {
  font-size: 1em;
  font-weight: 500; }

.mlText {
  text-align: justify; }

.textTitle {
  font-weight: 600;
  font-size: 1.5em; }

.menuIcon {
  color: #cacaca;
  padding-top: 5px; }

@media (max-width: 600px) {
  .menuIcon {
    padding-top: 0px; } }

.logOutBtn {
  cursor: pointer;
  padding-left: 15px;
  color: #a04d4d; }

.btn {
  background-color: #141414;
  color: #cacaca;
  border-radius: 5px; }

.btn:hover {
  background-color: #59b355;
  color: #141414; }

.dropzoneProfile {
  height: 100px;
  border-style: dashed;
  border-radius: 5px;
  background-color: #efefef;
  text-align: center;
  width: 200px;
  padding: 10px;
  margin: 0 auto;
  margin-bottom: 15px; }

.profilBtn {
  margin-top: 10px;
  border-radius: 5px; }

.profileDatePicker {
  margin-top: -20px !important; }

.profilePseudoInput {
  margin-top: -5px; }

#avatarScale {
  outline: none !important;
  border: none !important;
  width: 200px; }

.ril-toolbar {
  position: fixed !important;
  bottom: 0px !important;
  padding-left: 10px;
  padding-right: 10px; }

.ril__toolbar {
  top: auto; }

.ril-zoom-in {
  display: none !important; }

.ril-zoom-out {
  display: none !important; }

.ril-close {
  width: 40px !important;
  height: 40px !important;
  margin-right: -25px;
  border-radius: 50px; }

.ril__toolbarItem {
  overflow: visible; }

.ril__navButtons {
  display: none !important; }

